$ = jQuery.noConflict();

var $log = function (msg) {
        console.log(msg);
    },
    App = {
        init: function () {
            this.popups();
            this.scripts();
            this.mobMenu();
            this.sliders();
            this.tooltips();
            // this.popperTooltips();
        },

        scripts: function () {
            $('.top-search .search-icon').click(function (event) {
                event.preventDefault();
                $(this).parent().toggleClass('opened');
            });

            $('.section').click(function (event) {
                if ($('.top-search').hasClass('opened')) {
                    event.preventDefault();
                    $('.top-search').removeClass('opened');
                }
            });

            // Reference link
            $('a[data-tooltip]').click(function (e) {
                var id = $(this).attr('href');
                $('html,body').animate(
                    {
                        scrollTop: $(id).offset().top - 100
                    },
                    550
                );
            });

            var controller = new ScrollMagic.Controller({
                globalSceneOptions: {
                    triggerHook: 'onLeave'
                }
            });

            // build scenes
            var fieldAnim = new ScrollMagic.Scene({
                triggerElement: '.oska-fields-graph',
                duration: $('.graph-columns').height(),
                offset: -100,
                reverse: true
            })
                .setClassToggle('.oska-fields-graph', 'inView') // add class toggle
                //.addIndicators() // add indicators (requires plugin)
                .addTo(controller);

            var expertsSliderCircle = new ScrollMagic.Scene({
                triggerElement: '.experts-slider',
                duration: $('.experts-slider').height() + 100,
                offset: -250,
                reverse: true
            })
                .setClassToggle('.experts-slider', 'inView') // add class toggle
                .addTo(controller);

            var homeNavBlocks = new ScrollMagic.Scene({
                triggerElement: '.page-header',
                duration: $(window).height(),
                reverse: false
            })
                .setClassToggle('.home-nav-block.block-1, .home-nav-block.block-2, .home-nav-block.block-3, .home-nav-block.block-4, .home-nav-block.block-5', 'inView') // add class toggle
                .addTo(controller);

            var sectionDecos = new ScrollMagic.Scene({
                triggerElement: '.page-header',
                duration: $(window).height(),
                reverse: false
            })
                .setClassToggle('.section.field-section, .section.single-infographic', 'inView') // add class toggle
                .addTo(controller);

            var contactSlogans = new ScrollMagic.Scene({
                triggerElement: '.page-header',
                duration: $(window).height(),
                reverse: false
            })
                .setClassToggle('.contact-slogan-block', 'inView') // add class toggle
                .addTo(controller);

            /*$( '.oska-fields-graph:in-viewport(200)' ).removeClass( 'notInView' );
            
            $(window).scroll(function() {
            	$( '.oska-fields-graph:in-viewport(150)' ).removeClass( 'notInView' );
            });
            
            $( '.experts-slider:in-viewport(250)' ).addClass( 'inView' );
            
            $(window).scroll(function() {
            	$( '.experts-slider:in-viewport(250)' ).addClass( 'inView' );
            });*/

            // Sidebar accordion
            $('.side-accordion .collapsable').toggleClass('collapsed');
            $('.side-accordion .collapsable-header').click(function () {
                $('.collapsable.opened').not($(this).parent()).toggleClass('opened').toggleClass('collapsed').find('> .collapsable-content').slideToggle(400);
                $(this).parent().toggleClass('opened').toggleClass('collapsed').find('> .collapsable-content').slideToggle(400);

                var offset = 0,
                    self = $(this).parent();

                // setTimeout(function () {
                //     offset = self.offset();
                //     $('html,body').animate(
                //         {
                //             scrollTop: offset.top - 150
                //         },
                //         350
                //     );
                // }, 400);
            });

            // Megatrend Accordion
            $('.megatrend-content-block .collapsable').toggleClass('collapsed');
            $('.megatrend-content-block .collapsable-header').click(function () {
                $('.collapsable.opened').not($(this).parent()).toggleClass('opened').toggleClass('collapsed').find('> .collapsable-content').slideToggle(400);
                $(this).parent().toggleClass('opened').toggleClass('collapsed').find('> .collapsable-content').slideToggle(400);

                var offset = 0,
                    self = $(this).parent();

                setTimeout(function () {
                    offset = self.offset();
                    $('html,body').animate(
                        {
                            scrollTop: offset.top - 150
                        },
                        350
                    );
                }, 400);
            });

            var trendContentWrapper = $('.content-wrapper');

            if (trendContentWrapper.length) {
                $('.megatrend-content-block', trendContentWrapper).first().find('.collapsable').removeClass('collapsed').addClass('opened');
                $('.megatrend-content-block', trendContentWrapper).first().find('.collapsable-content').show();
            }

            // Fixed header
            var didScroll;
            var lastScrollTop = 0;
            var scrollVar = 30;
            var headerHeight = $('.page-header').outerHeight();

            $(window).scroll(function (event) {
                didScroll = true;
            });

            setInterval(function () {
                if (didScroll) {
                    hasScrolled();
                    didScroll = false;
                }
            }, 50);

            function hasScrolled() {
                var st = $(this).scrollTop();

                // If they scrolled down and are past the navbar, add class .nav-up.
                // This is necessary so you never see what is "behind" the navbar.
                if (st < scrollVar) {
                    // Scroll Down
                    $('body').removeClass('nav-down').addClass('nav-up');
                } else {
                    // Scroll Up
                    if (st + $(window).height() < $(document).height()) {
                        $('body').removeClass('nav-up').addClass('nav-down');
                    }
                }
            }
        },

        sliders: function () {
            var terminate_hover = function (slider) {
                    $('.slick-hover', slider).removeClass('slick-hover');
                },
                slider_buttons = function (slider) {
                    $('.slick-prev', slider)
                        .on('mouseenter', function () {
                            $('.slick-current', slider).prev().addClass('slick-hover');
                        })
                        .on('mouseleave', function () {
                            terminate_hover();
                        })
                        .on('click', function () {
                            $(this).trigger('mouseleave').trigger('mouseenter');
                        });

                    $('.slick-next', slider)
                        .on('mouseenter', function () {
                            $('.slick-current', slider).next().addClass('slick-hover');
                        })
                        .on('mouseleave', function () {
                            terminate_hover();
                        })
                        .on('click', function () {
                            $(this).trigger('mouseleave').trigger('mouseenter');
                        });
                };

            if (!$('.research-slider-images').hasClass('single-image') && $('.research-slider-images .slick-slider').length) {
                $('.research-slider-images .slick-slider').each(function () {
                    var $slider = $(this);

                    $(this).data(
                        'slider',
                        $(this).slick({
                            infinite: false,
                            speed: 800,
                            slidesToShow: 1,
                            centerMode: true,
                            centerPadding: '0',
                            variableWidth: false,
                            arrows: false,
                            dots: false,
                            autoplay: true,
                            autoplaySpeed: 10000,
                            pauseOnHover: true,
                            pauseOnDotsHover: true,
                            fade: true,
                            cssEase: 'ease-out',
                            asNavFor: '.research-slider-texts .slick-slider'
                        })
                    );
                });
            }

            if (!$('.research-slider-texts').hasClass('single-image') && $('.research-slider-texts .slick-slider').length) {
                $('.research-slider-texts .slick-slider').each(function () {
                    var $slider = $(this);

                    $(this).data(
                        'slider',
                        $(this).slick({
                            infinite: false,
                            speed: 500,
                            slidesToShow: 1,
                            centerMode: true,
                            centerPadding: '0',
                            variableWidth: false,
                            dots: true,
                            arrows: false,
                            autoplay: true,
                            autoplaySpeed: 10000,
                            pauseOnHover: true,
                            pauseOnDotsHover: true,
                            fade: false,
                            cssEase: 'ease-out',
                            asNavFor: '.research-slider-images .slick-slider'
                        })
                    );
                });
            }

            if (!$('.experts-slider-images').hasClass('single-image') && $('.experts-slider-images .slick-slider').length) {
                $('.experts-slider-images .slick-slider').each(function () {
                    var $slider = $(this);

                    $(this).data(
                        'slider',
                        $(this).slick({
                            infinite: false,
                            speed: 800,
                            slidesToShow: 1,
                            centerMode: true,
                            centerPadding: '0',
                            variableWidth: false,
                            arrows: false,
                            dots: false,
                            autoplay: true,
                            autoplaySpeed: 10000,
                            pauseOnHover: true,
                            pauseOnDotsHover: true,
                            fade: true,
                            cssEase: 'ease-out',
                            asNavFor: '.experts-slider-texts .slick-slider'
                        })
                    );
                });
            }

            if (!$('.experts-slider-texts').hasClass('single-image') && $('.experts-slider-texts .slick-slider').length) {
                $('.experts-slider-texts .slick-slider').each(function () {
                    var $slider = $(this);

                    $(this).data(
                        'slider',
                        $(this).slick({
                            infinite: false,
                            speed: 500,
                            slidesToShow: 1,
                            centerMode: true,
                            centerPadding: '0',
                            variableWidth: false,
                            dots: true,
                            arrows: false,
                            autoplay: true,
                            autoplaySpeed: 10000,
                            pauseOnHover: true,
                            pauseOnDotsHover: true,
                            fade: false,
                            cssEase: 'ease-out',
                            asNavFor: '.experts-slider-images .slick-slider'
                        })
                    );
                });
            }

            if (!$('.gallery-carousel').hasClass('single-image') && $('.gallery-carousel.slick-slider').length) {
                $('.gallery-carousel.slick-slider').each(function () {
                    var $slider = $(this);

                    $(this).data(
                        'slider',
                        $(this).slick({
                            infinite: true,
                            speed: 500,
                            slidesToShow: 3,
                            centerMode: true,
                            centerPadding: '220px',
                            variableWidth: false,
                            dots: false,
                            arrows: true,
                            autoplay: false,
                            cssEase: 'ease-out',
                            responsive: [
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 2,
                                        centerPadding: '160px'
                                    }
                                },
                                {
                                    breakpoint: 767,
                                    settings: {
                                        slidesToShow: 2,
                                        centerPadding: '80px'
                                    }
                                },
                                {
                                    breakpoint: 480,
                                    settings: {
                                        slidesToShow: 1,
                                        centerPadding: '40px'
                                    }
                                }
                            ]
                        })
                    );
                });
            }
        },

        mobMenu: function () {
            var menuWrap = $('.menu-wrap');

            /* toggle main menu nav */
            $('.menu-btn-wrap').click(function (event) {
                event.preventDefault();
                $(this).toggleClass('opened');
                menuWrap.toggleClass('opened');
                setTimeout(function () {
                    $('body').toggleClass('menu-opened');
                }, 300);
            });

            $(window).on('keyup', function (event) {
                if ($('body').hasClass('menu-opened')) {
                    switch (event.keyCode) {
                        case 27: //esc
                            $('.menu-btn-wrap').removeClass('opened');
                            menuWrap.removeClass('opened');
                            $('body').removeClass('menu-opened');
                            break;
                    }
                }
            });

            $('.section').click(function (event) {
                if ($('body').hasClass('menu-opened')) {
                    event.preventDefault();
                    $('.menu-btn-wrap').removeClass('opened');
                    menuWrap.removeClass('opened');
                    $('body').removeClass('menu-opened');
                }
            });
        },

        _showOverlay: function (name) {
            var body = $('body'),
                overlay = $('.modal-overlay'),
                close = $('.close', overlay),
                tint = $('.tint'),
                modal = $('.modal');

            body.addClass('noscroll');
            overlay.show();
            tint.addClass('fadeIn');
            overlay.attr('class', 'modal-overlay').addClass(name);
            $('.modal-content', overlay).hide();
            $('#' + name).show();
            modal.addClass('fadeInDownBig');
        },

        _hideOverlay: function () {
            var body = $('body'),
                overlay = $('.modal-overlay'),
                close = $('.close', overlay),
                tint = $('.tint'),
                modal = $('.modal');

            modal.addClass('fadeOutUp');
            tint.addClass('fadeOut');
            $('.modal-trigger.modal-active').removeClass('modal-active');

            setTimeout(function () {
                modal.removeClass('fadeInDownBig fadeOutUp');
                tint.removeClass('fadeOut fadeIn');
                overlay.hide().attr('class', 'modal-overlay');
                body.removeClass('noscroll');
                $('.modal .modal-content').hide();
            }, 1000);
        },
        share: {
            facebook: function (url) {
                var position_left = window.screen ? Math.round(screen.width / 2 - 275) : 100;
                window.open('https://www.facebook.com/sharer/sharer.php?u=' + url, 'share_facebook', 'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=550,height=420,top=100,left=' + position_left);
            },
            twitter: function (url) {
                var position_left = window.screen ? Math.round(screen.width / 2 - 275) : 100;
                window.open('https://twitter.com/intent/tweet?text=' + url, 'share_twitter', 'scrollbars=yes,resizable=yes,toolbar=no,location=yes,width=550,height=420,top=100,left=' + position_left);
            }
        },
        popups: function () {
            var body = $('body'),
                overlay = $('.modal-overlay'),
                tint = $('.tint'),
                close = $('.close', overlay),
                modal_triggers = $('.modal-trigger'),
                active_item = false;

            // Modal close button
            $('.close').click(function (e) {
                e.preventDefault();
                e.stopPropagation();
                App._hideOverlay();
            });

            // Attach popups event handlers
            modal_triggers.click(function (e) {
                e.preventDefault();

                var self = $(this),
                    overlay_id = self.data('modal'),
                    img = $('#' + overlay_id + ' .modal-img');

                modal_triggers.filter('.modal-active').removeClass('modal-active');
                self.addClass('modal-active');

                active_item = self;

                if (img.length != 0 && !img.hasClass('loaded')) {
                    img.attr('src', img.attr('data-src')).addClass('loaded');
                }

                App._showOverlay($(this).data('modal'));
            });

            overlay.click(function () {
                App._hideOverlay();
            });

            $('.modal-content', overlay).click(function (e) {
                e.stopPropagation();
            });

            $(window).on('keyup', function (event) {
                if (modal_triggers.filter('.modal-active').length) {
                    switch (event.keyCode) {
                        case 37: //left
                            prev_btn.trigger('click');
                            break;
                        case 39: //right
                            next_btn.trigger('click');
                            break;
                        case 27: //esc
                            App._hideOverlay();
                            break;
                    }
                }
            });
        },
        tooltips: function () {
            var tips = $('[data-tooltip]');

            tips.each(function (i, o) {
                var $this = $(this);

                tippy(this, {
                    appendTo: document.body,
                    content: $this.data('tooltip'),
                    allowHTML: true,
                    interactive: true,
                    placement: 'auto-start',
                    altBoundary: 'false',
                    delay: [50, 300],
                    popperOptions: {
                        strategy: 'absolute',
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 5]
                                }
                            },
                            {
                                name: 'flip',
                                enabled: 'true',
                                options: {
                                    fallbackPlacements: ['top', 'bottom']
                                }
                            },
                            {
                                name: 'preventOverflow',
                                enabled: 'true',
                                options: {
                                    altAxis: false,
                                    tether: false,
                                    padding: 0
                                }
                            }
                        ]
                    }
                });
            });
        },
        popperTooltips: function () {
            const tooltipContainer = document.querySelectorAll('.panel-custom');
            var popperInstance;
            var button;
            var tooltip;

            tooltipContainer.forEach((tooltipContainer) => {
                button = document.querySelector('.button');
                tooltip = document.querySelector('.tooltip');
                popperInstance = Popper.createPopper(button, tooltip, {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 8]
                            }
                        }
                    ]
                });
            });
            function show() {
                // Make the tooltip visible
                tooltip.setAttribute('data-show', '');

                // Enable the event listeners
                popperInstance.setOptions((options) => ({
                    ...options,
                    modifiers: [...options.modifiers, {name: 'eventListeners', enabled: true}]
                }));

                // Update its position
                popperInstance.update();
            }

            function hide() {
                // Hide the tooltip
                tooltip.removeAttribute('data-show');

                // Disable the event listeners
                popperInstance.setOptions((options) => ({
                    ...options,
                    modifiers: [...options.modifiers, {name: 'eventListeners', enabled: false}]
                }));
            }

            const showEvents = ['mouseenter', 'focus'];
            const hideEvents = ['mouseleave', 'blur'];

            showEvents.forEach((event) => {
                button.addEventListener(event, show);
            });

            hideEvents.forEach((event) => {
                button.addEventListener(event, hide);
            });
        }
    };

$(document).ready(function () {
    App.init();
});
